<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Warehouses')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Reports')"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          @click="showReports"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses,
            types: types
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="openCloseFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="warehouses"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="warehouseLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="transaction"
                :props="props"
              >
                <q-btn
                    v-if="isSupervisior || isAdministrator"
                    size="sm"
                    color="green-6"
                    icon="add"
                    :label="this.$t('Add transaction')"
                    unelevated
                    @click="handleAddTransaction(props.row)"
                />
              </q-td>

              <q-td
                key="type"
                :props="props"
              >
                {{ props.row.type }}
              </q-td>

              <q-td
                key="country"
                :props="props"
              >
                <span v-if="props.row._embedded.country">
                  {{ props.row._embedded.country.name }}
                </span>
              </q-td>

              <q-td
                key="currency"
                :props="props"
              >
                <span v-if="props.row._embedded.currency">
                  {{ props.row._embedded.currency.name }}
                </span>
              </q-td>

              <q-td
                key="parent"
                :props="props"
              >
                <span v-if="props.row._embedded.parent">
                  {{ props.row._embedded.parent.name }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  v-if="isClient"
                  :color="stateColors[props.row.state]"
                  :label="$t(props.row.state)"
                />

                <q-toggle
                  v-else
                  color="light-blue-9"
                  :model-value="props.row.state === 'active'"
                  @update:model-value="handleStateChange(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
    <transaction-warehouse-modal ref="transactionModal" :warehouse="selectedWarehouse"/>
    <warehouse-reports ref="warehouseReports" />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import WarehouseReports from '../../components/reports/WarehouseReports'
import Search from '../../components/search/Search.vue'
import TransactionWarehouseModal from "@/apps/app/components/modals/TransactionWarehouseModal.vue";

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'


/**
 * Create Date
 *
 * @param {object} date
 *
 * @returns {String}
 */
function createDate (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

export default {
  name: 'Acceptances',
  components: {
    TransactionWarehouseModal,
    WarehouseReports,
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      selectedWarehouse: null,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: '',
          name: 'transaction',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Country'),
          name: 'country',
          align: 'left'
        },
        {
          label: this.$t('Currency'),
          name: 'currency',
          align: 'left'
        },
        {
          label: this.$t('Parent'),
          name: 'parent',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark'
      },
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      types: [
        { id: 'client', title: 'Client' },
        { id: 'virtual', title: 'Virtual' },
        { id: 'fulfillment', title: 'Fulfillment' }
      ],
      activatedFields: [
        'state',
        'id',
        'name',
        'owner',
        'created.from',
        'created.to',
        'type',
        'country'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'warehouses',
      'totalWarehouseNumber',
      'warehousePage',
      'warehouseLoading',
      'appOptions',
      'isSupervisior',
      'isAdministrator'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.totalWarehouseNumber
        : this.$t('Filter')
    }
  },
  mounted () {
    this.pagination = {
      ...this.pagination,
      page: this.warehousePage,
      rowsNumber: this.totalWarehouseNumber
    }

    if (this.warehouses.length > 0) {
      return
    }

    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadWarehouses',
      'loadAccounts'
    ]),
    ...mapMutations([
      'setWarehouse',
      'upsertWarehouse'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.warehouse.save({ state }, item.id)
        .then(item => {
          this.upsertWarehouse(item)
        })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadWarehouses(this.serverParams)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          return { items, totalPages, page, totalItems }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    showReports () {
      this.$refs.warehouseReports.open()
    },
    create () {
      this.$router.push('/storage/warehouses/entity')
    },
    onRowDblClick (row) {
      this.setWarehouse(row)
      this.$router.push('/storage/warehouses/entity/' + row.id)
    },
    handleAddTransaction (warehouse) {
      let data = {
        entityType: 'Orderadmin\\Storage\\Entity\\Warehouse',
        entity: warehouse.id,
        state:'confirmed',
        transactionDate: createDate(new Date())
      }
      const query = buildQuery({})

      query.filter = [
        {type: 'eq' ,field: 'type', value: 'deposit'}
      ]

      if(warehouse && warehouse._embedded && warehouse._embedded.owner) {
        query.filter.push({type: 'eq' ,field: 'owner', value: warehouse._embedded.owner.id})
      } else {
        return
      }

      return this.loadAccounts(query)
        .then(({ items }) => {
          if(items && items.length > 0) {
            const account = items[0]
            data.account = account.id
            this.selectedWarehouse = warehouse
            this.$refs.transactionModal.open(data)
          }

          return { items }
        })

    },
  }
}
</script>
